/* Fonts */
/* Migra Serif */
@font-face {
  font-family: 'Migra';
  font-style: normal;
  font-weight: normal;
  src: local('Migra'), url(./fonts/Migra/Migra-Extralight.woff) format('woff');
}

@font-face {
  font-family: 'Migra';
  font-style: italic;
  font-weight: normal;
  src: local('Migra'), url(./fonts/Migra/MigraItalic-ExtralightItalic.woff) format('woff');
}

@font-face {
  font-family: 'Migra';
  font-style: bold;
  font-weight: bold;
  src: local('Migra'), url(./fonts/Migra/Migra-Extrabold.woff) format('woff');
}

@font-face {
  font-family: 'Migra';
  font-style: italic;
  font-weight: bold;
  src: local('Migra'), url(./fonts/Migra/MigraItalic-ExtraboldItalic.woff) format('woff');
}

/* Pier Sans */
@font-face {
  font-family: 'PierSans';
  font-style: normal;
  font-weight: normal;
  src: local('PierSans'), url(./fonts/PierSans/PPPierSans-Regular.otf) format('opentype');
}

@font-face {
  font-family: 'PierSans';
  font-style: normal;
  font-weight: bold;
  src: local('PierSans'), url(./fonts/PierSans/PPPierSans-Bold.otf) format('opentype');
}

@font-face {
  font-family: 'PierSans';
  font-style: italic;
  font-weight: normal;
  src: local('PierSans'), url(./fonts/PierSans/PPPierSans-Italic.otf) format('opentype');
}

@font-face {
  font-family: 'PierSans';
  font-style: italic;
  font-weight: bold;
  src: local('PierSans'), url(./fonts/PierSans/PPPierSans-BoldItalic.otf) format('opentype');
}

/* Bitmap */
@font-face {
  font-family: 'Mondwest';
  src: local('Mondwest'), url(./fonts/Bitmap/PPMondwest-Regular.otf) format('opentype');
}

@font-face {
  font-family: 'NeueBit';
  src: local('NeueBit'), url(./fonts/Bitmap/PPNeueBit-Bold.otf) format('opentype');
}


/* Initialising theme */
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Migra', 'PierSans', 'Mondwest', 'NeueBit', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f5ebe0;
  /* overflow-x: hidden; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* html {
  overflow-x: hidden;
} */


/* Landing page */
#page {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: auto 0;
  overflow-x: hidden;
}

.curvedtext {
  font-family: 'PierSans';
  position: fixed;
  bottom: 0;
  right: 0;
}

.landing__textbox {
  font-family: 'Mondwest';
  font-size: calc(100% + 4vh);
  margin-top: calc(20% + 1vh);
  margin-left: 5%;
  margin-right: 10%;
  width: auto;
  height: 100%;
  flex-wrap: wrap;
  align-items: center;
  
}

.landing__textbox img {
  width: 1em;
  vertical-align: middle;
  padding-bottom: 0.1em;
}

.landing__highlight {
    background-color:#48f971;
    border-radius:0%;

}

.landing__underline {
  text-decoration: 2px underline #0000ff;
  text-underline-offset: 5px;

}

/* Projects page */
#page__projects {
  height: 100%;
  width: 100%;
  display: 'flex';
  flex-direction: column;
  z-index: -1;
}

#placeholder {
  min-height: 13vh;
}

#mini-placeholder {
  min-height: 6vh;
}

.project__title__h1 {
  z-index: -1;
  font-family: 'PierSans';
  font-style: 'normal';
  font-size: calc(100% + 1vw);
  position: relative;
  left: 0;
  text-align: left;
  padding-left: 1em;
  padding-top: .5em;
  
}

.project__title__h1__sub {
  font-family: 'Migra';
  font-style: normal;
}

.project__card__title__number {
  font-family: 'Migra';
  font-size: calc(30% + 1vw);
  padding: 2vw;
}

.project__card__title__text {
  font-family: 'Migra';
  font-weight: normal;
  font-size: calc(100% + 5vw);
  position: absolute;
  right: 0;
  padding-right: .5em;
  z-index: -1;  
}

.project__info {
  margin-top: 1em;
  width: 100%;
}

.project__desc {
  font-family: 'NeueBit';
  font-size: calc(60% + 2vw);
  float: left;
  width: 50%;
  flex-wrap: wrap;
}

.project__stack {
  font-family: 'NeueBit';
  font-weight: bold;
  font-size: calc(40% + 2vw);
  float: right;
  flex-wrap: wrap;
  max-width: 30%;
}

.project__stack__link {
  font-family: 'NeueBit';
  font-style: italic;
  font-weight: normal;
  letter-spacing: 2px;
  position: relative;
  font-size: calc(30% + 1vw);
}

.project__stack__link a {
  padding-left: 5px;
}

.project__container {
  display: flex;
  overflow-x: scroll;
  width: 100%;
  padding-top: 1em;
  align-items: center;  
  padding-left: 1em;
}

/* Contact page */
.contact__textbox {
    font-family: 'Migra';
    font-style: normal;    
    font-size: calc(100% + 3vw);
    text-align: center;
    justify-content: center;
    align-items: center;
    letter-spacing: 3px;
}

.contact__container img{
  max-height: 1em;
  padding: 7px 10px;
}

.contact__tooltip {
  letter-spacing: 1px;
}

.contact__icon {
  width: 1em;      
  padding: 0px 10px;
  vertical-align: middle;
}






